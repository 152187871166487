<template>
  <v-dialog v-model="dialog" scrollable max-width="800px" @click:outside="clickOutsideHandler">
    <template #activator="{ on: dialog }">
      <v-tooltip left>
        <template #activator="{ on: tooltip }">
          <span v-on="{ ...tooltip }">
            <v-btn
              large
              color="secondary"
              class="ma-2 white--text"
              v-if="!editMode"
              :disabled="disabled"
              @click="onShowDialog"
              v-on="{ ...dialog }"
            >Add Manual Guide Pin
            </v-btn>
            <v-icon
              v-else
              class="mx-1"
              color="primary"
              small
              v-on="{ ...dialog }"
              @click="onShowUpdate"
              :disabled="disabled"
              v-show="!itemData.edited"
            >
              mdi-pencil
            </v-icon>
          </span>
        </template>
        <span>{{
          disabled
            ? "Update is no longer available"
            : editMode
            ? "Edit Guide Pin"
            : "Displays a modal to enter specific details"
        }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >{{ editMode ? "Edit" : "New" }} Guide Pin</span
        ><v-spacer /><span
          v-if="override"
          class="headline font-weight-bold red--text text--darken-1"
          >Override Validation Controls is enabled</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="name *"
                  name="name"
                  type="text"
                  autocomplete="off"
                  autofocus
                  v-model.lazy="f_formData.name"
                  :rules="rules.requireCheck"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="X-Coordinate (mm) *"
                  name="xCoordinate"
                  type="number"
                  v-model.number="f_formData.x"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeCoord({ coord: 'x', toMils: true })"
                />
                <v-text-field
                  v-else
                  label="X-Coordinate (mils) *"
                  name="xCoordinateMils"
                  type="number"
                  v-model.number="f_formData.x_mils"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeCoord({ coord: 'x', toMils: false })"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="Y-Coordinate (mm) *"
                  name="yCoordinate"
                  type="number"
                  v-model.number="f_formData.y"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeCoord({ coord: 'y', toMils: true })"
                />
                <v-text-field
                  v-else
                  label="Y-Coordinate (mils) *"
                  name="yCoordinateMils"
                  type="number"
                  v-model.number="f_formData.y_mils"
                  step="0.0001"
                  :rules="rules.notNull"
                  @change="computeCoord({ coord: 'y', toMils: false })"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="!enabledViewMils"
                  label="Diameter (mm) *"
                  name="diameter"
                  type="number"
                  v-model.number="f_formData.diameter"
                  step="0.0001"
                  :rules="rules.requireCheck"
                  @change="computeCoord({ coord: 'diameter', toMils: true })"
                />
                <v-text-field
                  v-else
                  label="Diameter (mils) *"
                  name="diameterMils"
                  type="number"
                  v-model.number="f_formData.diameter_mils"
                  step="0.0001"
                  :rules="rules.requireCheck"
                  @change="computeCoord({ coord: 'diameter', toMils: false })"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="guidePinLayers"
                  label="Layer *"
                  item-text="description"
                  item-value="pk"
                  v-model.lazy="f_formData.layer"
                  :rules="rules.requireCheck"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="filteredAssembly"
                  label="Guide Pin *"
                  item-text="description"
                  item-value="pk"
                  v-model="f_formData.guide_pin_assembly"
                  :rules="rules.requireCheck"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="yesNo"
                  label="Enabled *"
                  item-text="text"
                  item-value="value"
                  v-model.lazy="f_formData.enabled"
                  :rules="rules.notNull"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn color="warning" @click="onClose">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave">Save</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "GuidePinForm",
  props: {
    editMode: Boolean,
    itemData: Object,
    override: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    enabledViewMils: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      f_formData: {},
      dialog: false,
      yesNo: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      rules: {
        requireCheck: [(v) => !!v || this.override || "Input is required"],
        notNull: [(v) => v != null || this.override || "Input is required"],
        requirePlus: [
          (v) =>
            (!!v && v >= 0) ||
            this.override ||
            "Value should be equal or higher than 0",
        ],
      },
      MILS_TO_MM: 0.0254,
    };
  },
  computed: {
    ...mapGetters({
      guidePins: "guidepins/guidePins",
      guideAssembly: "dutconfigs/guidePins",
      guidePinLayers: "dutconfigs/guidepinLayers",
      dutConfigs: "dutconfigs/dutConfigs",
      fixtureConfig: "fixturedesign/fixtureConfig",
    }),
    filteredAssembly() {
      const aList = this.override
        ? this.guideAssembly
        : this.guideAssembly.filter((element) => {
            return element.fixtures.includes(this.fixtureConfig.pk);
          });
      return [...aList].sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
    },
  },
  methods: {
    onShowUpdate() {
      this.f_formData = {
        ...this.itemData,
        layer: this.itemData.layerPk,
        guide_pin_assembly: this.itemData.guide_pin_assemblyPk,
      };
    },
    onShowDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.f_formData = {
        project: +this.$route.params.id,
        dut_config: this.dutConfigs.pk,
        name: null,
        x: 0.0000,
        y: 0.0000,
        diameter: 0.0000,
        x_mils: 0.0000,
        y_mils: 0.0000,
        diameter_mils: 0.0000,
        layer: 2,
        cbore_collar: false,
        guide_pin_assembly: null,
        enabled: true,
      };
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const actionPayload = {
        editMode: this.editMode,
        data: { ...this.f_formData },
      };
      this.$emit("saveData", actionPayload);
      this.$refs.form.reset();
      this.dialog = false;
    },
    clickOutsideHandler() {
      this.$refs.form.reset();
    },
    computeCoord({ coord, toMils }) {
      if (toMils) {
        this.f_formData[`${coord}_mils`] = (this.f_formData[coord] * (1 / this.MILS_TO_MM)).toFixed(4);
        this.f_formData[coord] = this.f_formData[coord].toFixed(4) ;
      } else {
        this.f_formData[coord] = (this.f_formData[`${coord}_mils`] * this.MILS_TO_MM).toFixed(4);
        this.f_formData[`${coord}_mils`] = this.f_formData[`${coord}_mils`].toFixed(4)
      }
    },
    onInputString() {
      return "if (this.value > 2147483647) this.value = 2147483647; if (this.value < 0) this.value = 0;"
    }
  },
};
</script>
